import react from "react";
import "bootstrap/dist/css/bootstrap.min.css"

const AAA=()=>{
    return (
        <>
        <h1> Como es esto </h1>
        <h2> con un curso gratis, de udemy, esto es una app que utiliza diferentes tcnologias, node js, react, typescrips, es una version de un curso en donde usamos una api, la de pokemon.  </h2>
        </>
    )
}
export default AAA;