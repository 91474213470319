import react from "react";
import "bootstrap/dist/css/bootstrap.min.css"

const Que=()=>{
    return (
        <>
        <h1> Que es esto </h1>
        <h2> esto es una app que utiliza diferentes tcnologias, node js, react, typescrips, es una version de un curso en donde usamos una api, la de pokemon.  </h2>
        </>
    )
}
export default Que;